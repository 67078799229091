<template>
  <div class="content">
    <nav class="nav-bar">
    <h1 class="nav-title">Filter</h1>
      <ul class="nav-items">
        <li class="nav-item" v-for ="category in categories" :key="category.id">
          <div v-on:click = "projectFilterKey = category.name; scrollToTop();" class="clickable button button_inactive" :class="{ button_active: projectFilterKey == category.name }" >
            <div class="button_square" :style="{backgroundColor: category.description}" > </div>   <span class="button_title">{{ category.name }}</span>
          </div>
        </li>
      </ul> 
      <img class="logo" src="../assets/logos/p_logo.svg" alt="logo">
    </nav>

   <div class ="animation-space">
      <transition-group name="fade" mode="in-out" tag="ul" class="project-space"
      @before-leave="beforeLeave">
        <li class = "projects" v-for = "project in projectFilter" :key="project.id" >
          
          <div v-on:click="toggleProject(project)" class="">
            <transition-group name="flip" tag="div" class="transition_flip">

              <div key=1 v-if ="!project.showDetails" class="project-front" >
                <div class ="image_container clickable">
                  <img class="project-img" v-bind:src="project._embedded['wp:featuredmedia'][0].source_url" >
                </div>
                <div class="title-container">
                  <span class="project-title clickable" v-html="project.title.rendered"> </span>
                </div>
              </div>
  
              <div  key=2 v-else class="project-back"> 
                <span class="description-flex">
                  <span class="project-title clickable" v-html="project.title.rendered"> </span>
                    <!-- <p class="see-project"><a :href="stripHTML(project.excerpt.rendered)" target="_blank" rel="noopener noreferrer"> <img src="../assets/logos/eye.svg" alt="see project" class="eye"><span class="eye-desc">See Project</span></a></p> -->
                    <span class="description clickable" v-html="project.content.rendered"></span>
                    
                    <a v-if ="stripHTML(project.excerpt.rendered) != 0" :href="stripHTML(project.excerpt.rendered)" target="_blank" rel="noopener noreferrer" class="launch-button" > 
                        See Project
                        
                    </a>

                </span>
              </div>

            </transition-group>

            <transition name="flip">
                <div key=3 class="bottom_highlight" :style="{backgroundColor: categoryColor(project.categories)}" > </div>
            </transition>
          </div>
        </li>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectSpace',
  props: {
    msg: String,
  },
  
  data() {
    return{
      projectFilterKey: 'all',
      projects: [],
      categories: [],
      // showDetails: false
    }
  },
  methods:{
    toggleProject(el) {
      this.projects.forEach(project =>{
        if(el.id == project.id) {
          el.showDetails = !el.showDetails;
        }
        else{
          project.showDetails = false ;
        }
      })

    },

    scrollToTop() {
      window.scrollTo(0,0);
    },

    beforeLeave(el) {
      const { width, height} = window.getComputedStyle(el)
      // el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`
      // el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`
      el.style.width = width
      el.style.height = height
    },

    stripHTML(el) {
      let regex = /(<([^>]+)>)/ig;
      return el.replace(regex, "");
    },

    // Find category name and set color 
    categoryColor(...cat){
      let catArgs = Object.entries(cat);
      let match = [];

      this.categories.forEach(color => {
          catArgs[0][1].forEach( cat =>{
              if(color.id==cat ){
                if (color.name != 'All') match.push(color.description);
              }
            }
          )
        }
      )    

      return match;
    },

  },
  created: function(){
    this.$http.get('wp/v2/posts?_embed&per_page=100').then(response => {
        // console.log(response.data);
        for(let project in response.data){
          Object.assign(response.data[project], {showDetails:false});
          this.projects.push(response.data[project])
        }
    }, error => {
        alert(error);
    });

    this.$http.get('wp/v2/categories').then(response => {
        // console.log(response);
        for(let category in response.data){
          this.categories.push(response.data[category])
        }

    }, error => {
        alert(error);
    });
  },
  computed:{
    projectFilter(){
      let filterList=[];
      
      this.projects.forEach(project =>{
        project.showDetails = false;
      })

      if(this.projectFilterKey == 'all'){
        return this.projects;
      } else {
        this.projects.forEach(
          project => {
            project._embedded['wp:term'][0].forEach( category =>{
              // console.log(project._embedded['wp:term'][0]);
                if ( this.projectFilterKey == category.name){
                  filterList.push(project);
              }
            })
          }
        );
        return filterList
      }
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.launch-button{
  padding: 5px 10px;
  text-align: center;
  font-weight: bold;
  transition-duration: 0.4s;
  cursor: pointer;
  width:60%;
  border-radius: 8px;
  align-self:center;
  background-color: #4D7EC8;
  color: white;
  border: 3px solid #4D7EC8;
  margin-bottom:1vw;
}

.launch-button:hover {
  background-color: #ffffff; 
  color: #4D7EC8; 
  border: 3px solid #4D7EC8;
}

.flip-enter-active {
  transition: all .4s ease;
  min-height:100%;
}

.flip-leave-active {
  min-height:100%;
  display: none;
}

.flip-enter, .flip-leave {
  min-height:100%;
  transform: rotateY(180deg);
  opacity: 0;

}

a {
  color:inherit;
  margin:0;
  padding:0;
  text-decoration: none;
}

ul {
  /* list-style-type: none; */
  padding: 0;
  margin:0;
}

li {
  display: inline-block;
}

.clickable{
    cursor:pointer; 
}

.button {
  padding-top:2vw;
  padding-bottom:2vw;
  font-weight: bold;
  display:flex;
  align-items: center;
}

.title-container {
  padding-top:2vw;
  padding-bottom:2vw;
  font-weight: bold;
  display:flex;
  align-items: center;
  flex-direction: column;
}
.button_inactive {
  opacity:.5;
  transition: all 200ms ease-in;
}

.button_active {
  opacity:1;
  transition: all 200ms ease-in;
}

.button_square  {
  display: inline-flex;
  height:1.5em;
  width:1.3em;
}

.button_title{
  margin-top:.1rem;
  font-size:2.3vh;
  padding-left:.7em;
}

.content {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 300;
  color: #3d5358;
  min-height:100%;
  min-width: 55%;
  margin: 0;
}

.nav-title{
  margin:0;
  font-size:2.4vh;
  color:#8E8E8E;
  padding-left:1em;
  letter-spacing: 2px;
}

.logo {
  height:2.2em;
  padding-right: 1em;
  margin-left:67%;
  position:fixed;
}

.nav-items {
  color:#606060;
}

.nav-item {
  color:#606060;
  padding-left:2em;
}

.nav-bar {
  display:flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  background-color: #F6F6F6;
  height:4rem;
  overflow: hidden;

  /* Set Fixed Bar */
  position:fixed;
  width:100%;     
  z-index:200; 
  filter: drop-shadow(0px 1px 4px #939393);
}

.project-title {
  color:#5E5E5E;
  font-weight:bold;
  font-size: 2vw;
  text-align: center;
}

.project-title-back {
  color:#5E5E5E;
  font-weight:bold;
  font-size: 2vw;
  padding-left: 1vw;
  text-align: center;
}

.animation-space{
  margin-top:70px;
  padding-top:0;



    max-height:100%;
    transition: all .3s ease;
}

.image_container{
  width:100%;
  overflow:hidden;
  max-height:18.8vw;
}
.project-img {
  display: block;
  width: 100%;
  min-height:18.8vw;
  height: auto;
  filter: drop-shadow(0px 2px 2px #939393);
  opacity: .8;
}


.project-front{
  /* height:100%; */
  background-color: #f8f8f8;

  min-height:100%;
  transition: all .3s ease;
}

.project-back{
  padding: 4%;
  background-color: #f8f8f8;

  min-height:100%;
  transition: all .3s ease;
}

.bottom_highlight  {
  height:.8em;

}

.project-space{
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  /* height: 100%; */



    min-height:100%;
    transition: all .3s ease;
}
.transition_flip {
  display:flex;
  flex-wrap:column nowrap;
  justify-content: space-between;
  flex:1 100%;
  min-height:25.5vw;


    /* height:100%; */
    transition: all .3s ease;
}
.projects{
  flex: 1 33%;
  padding:.5vw;
  background-color: #EBEBEB;
  max-width:46.4%;
  margin-right:1.5vw;
  margin-bottom: .5vw;
  backface-visibility: hidden;
  z-index: 1;


    height:100%;
    transition: all .3s ease;

}
.see-project{
  /* display:flex; */
  /* align-items:; */
  /* justify-content:flex-start; */
  margin:0px;
}

.eye-desc{
  position:relative;
  top:-.7vw;
}

.eye{
  display:inline-flex;
  /* flex-wrap:nowrap; */
  align-content: baseline;
  align-items: baseline;
  /* margin:0px; */
  justify-content:flex-start;
  height:2.5vw;
  opacity:.5;
  padding-right:1vw;
}

.description{
  /* min-height:20.5vw; */
  flex-grow: 1;
  line-height: 1.6;


      height:100%;
    transition: all .3s ease;
}

.description-flex{
  height:100%;
  padding: 1vw;
  font-family: 'Red Hat Display', sans-serif;
  color: #5E5E5E;
  display:flex;
  flex-direction:column;
  justify-content: space-between;


  height:100%;
  transition: all .3s ease;
}

.fade-transition{
  transform:all 1s ease;
}

.fade-enter-active{
  transition: all 200ms ease-out
} 

.fade-leave-active {
  /* transform:all 1s ease; */
    transition: all 200ms ease-in;
    position: absolute;
    transform:scale(.7);
    z-index: 0;
}

 .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-enter{
  transform:scale(.7)
}

.fade-move {
  transition: all 200ms ease-in-out 200ms
}

.project-fade-enter-active,
.project-fade-leave-active {
    transition:all .5s ease;
    overflow:hidden;
    max-height: 800px;
}

.project-fade-enter,
.project-fade-leave-to {
    opacity: 0;
    max-height:0;
}







@media only screen and (max-width: 1000px) {

  .logo{
    display:none;
  }

  .nav-title{
    /* margin-right:auto; */
    /* padding:1vw; */
  }

  .nav-bar{
    display:flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content:flex-start;
    background-color: #F6F6F6;
    height:4rem;
    overflow: hidden;
    position: inherit;
    width:100%;     
    filter: drop-shadow(0px 1px 4px #939393);
  }

  .nav-items {
    color:#606060;
  }

  .nav-item {
    color:#606060;
    padding-left:1em;
  }

  .eye-desc{
    position:relative;
    top:-1.5vw;
  }
  
   .animation-space{
    margin-top:10px;
    padding-top:0;

    height:100%;
    transition:all .3s ease;
  }

  .project-space{
    display:flex;
    flex-wrap:wrap;
    flex-direction: column;

    height:100%;
    transition:all .3s ease;
  }

  .projects{
    background-color: #EBEBEB;
    margin-bottom: 1vw;
    min-width:100%;
    padding:1vw;
    backface-visibility: hidden;
    z-index: 1;

    min-height:100%;
    transition: all 0.3s ease;
  }

  .transition_flip{
    background-color: #EBEBEB;
    display:flex;
    flex-wrap:row nowrap;
    justify-content: space-between;
    background-color: #EBEBEB;
    min-height:25.5vw;
    
    min-height:100%;
    transition: all 0.3s ease;
  }

 .project-front{
    display:flex;
    align-items: center;
    flex-direction: row;
    transition: all .3s ease;
    width:100%;
    background-color: #f8f8f8;

    min-height:100%;
    transition: all 0.3s ease-out;
  }

  .image_container{
    width:40%;
    overflow:hidden;
    max-height:22.8vw;
  }

  .project-img {
    display: block;
    width: 100%;
    min-height:22.8vw;
    height: auto;
    filter: drop-shadow(0px 2px 2px #939393);
    opacity: .8;
  }

  .project-back {
    padding: 4%;
    background-color: #f8f8f8;

    height:100%;
    transition: all .3s ease;
  }

  .bottom_highlight  {
    height:.4em;
  }


  .button_square  {
    display: inline-flex;
    height:1.4em;
    width:1.2em;
  }

  .button {
    padding:1vw;
    padding-left:1vw;
    font-weight: bold;
    display:flex;
    align-items: center;
  }

  .project-title{
    color:#5E5E5E;
    font-weight:bold;
    font-size: 3.8vw;
    padding-left: 2vw;
    opacity:.9;
    justify-content: center;
    align-items:center;
  }

  .description{
    /* min-height:20.5vw; */
    flex-grow: 1;
    line-height: 1.6;

    min-height:100%;
    transition: all .3s ease;
  }

  .description-flex{
    height:100%;
    padding: 1vw;
    font-family: 'Red Hat Display', sans-serif;
    color: #5E5E5E;
    display:flex;
    flex-direction:column;
    justify-content: space-between;

    min-height:100%;
    transition: all .3s ease;
  }


  .eye{
    height:4.5vw;
    opacity:.5;
    padding-right:1vw;
  }







}

</style>
