<template>
  <div id="app">
    <div class ="wrapper">
      <!-- <router-link to="/test">test</router-link> -->
      <!-- <router-view></router-view> -->
      <SideBar />
      <ProjectSpace />
    </div>
  </div>
</template>

<script>
import ProjectSpace from './components/ProjectSpace.vue'
import SideBar from './components/SideBar.vue'


export default {
  name: 'App',
  components: {
    ProjectSpace,
    SideBar
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500;700&display=swap');

  h1{
    padding:0;
    margin:0;
  }


  #app {
    font-family: 'Red Hat Display', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body, html, #app {   
    height: auto;
    background-color: #D6D6D6;
    min-height: 100%; 
    margin:0;
    padding:0;
    }
  html{
    overflow-y: scroll;
  }

  .wrapper {
    display: flex;
    flex-flow:column nowrap;
    justify-content: flex-start;
    align-items:stretch;
  }

    @media only screen and (min-width: 1000px) {
      .wrapper{
        display: flex;
        flex-flow:row nowrap;
        justify-content: flex-start;
        align-items:stretch; 
      }
  }
</style>
