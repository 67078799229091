<template>
  <div class="content">
    <div class="group">
      <div class="title-block">
        <h1 class="subtitle">projects</h1>  
        <h1 class="title">Patrick Beard</h1>
        <hr>
      </div>
            
      <div class="about">
        <p class="description"> Welcome to the personal project page of Patrick Beard.</p>

        <p class="github"><a href="https://github.com/pdbeard" target="_blank" rel="noopener noreferrer"> <img src="../assets/logos/github.svg" alt="github"></a></p>
      </div>
    </div>
  

  </div>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    msg: String,
  },
  data() {
    return{

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

hr {
  border: 2px solid #4D7EC8;
}

.group{
  padding:2em;
  padding-right:6vw;
}
.title{

  font-weight:bold;
  font-size: 3vw;
}

.subtitle{
  font-weight:500;
  font-size: 2.0vw;
  color:#A8A8A8;
}

.content {
  font-family: 'Red Hat Display', sans-serif;
  color: #5E5E5E;
  min-width: 30%;
  min-height:100%;
  margin: 0;
}




@media only screen and (max-width: 1000px) {
  hr {
    /* display:none; */
  }

  .github{
    display:none;
  }
  .group{
    padding:0;
    display:flex;
    justify-content: space-between;
    align-content: center;
  }
  .title-block{
    padding-left:2vw ;
    padding-right:1vw;
    min-width:30%;
  }
  .title{
    font-weight:bold;
    font-size: 4.0vw;
  }

  .subtitle{
    font-weight:500;
    font-size: 3.0vw;
    color:#A8A8A8;
  }

  .content {
    font-family: 'Red Hat Display', sans-serif;
    color: #5E5E5E;
    min-width: 20%;
    min-height:100%;
    margin: 0;
  }

  .about{
    padding-top:2vw;
  }

  .description{
    display:none;
  }

}

</style>
